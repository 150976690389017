import { Controller } from '@hotwired/stimulus'
import { ActiveStorageUploadPlugin } from '../uppy-activestorage-upload-plugin'
import type Uppy from '@uppy/core'
import type Dashboard from '@uppy/dashboard'

// Our Dwell 3 style overrides
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '../styles/uppy.css'

export default class extends Controller {
  static targets = ['trigger', 'preview', 'field']
  static values = {
    accept: Array,
    directUploadUrl: String,
  }

  declare uppyCoreModule: typeof Uppy
  declare uppyDashboardModule: typeof Dashboard

  declare acceptValue: string[]
  declare directUploadUrlValue: string
  declare fieldTarget: HTMLElement
  declare triggerTarget: HTMLElement
  declare uppy: Uppy
  declare signedId: string

  async connect() {
    if (!this.uppyCoreModule) {
      await import('@uppy/core').then(
        (module) => (this.uppyCoreModule = module.default)
      )
    }
    if (!this.uppyDashboardModule) {
      await import('@uppy/dashboard').then(
        (module) => (this.uppyDashboardModule = module.default)
      )
    }
    this.uppy = new this.uppyCoreModule({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: this.acceptedFileTypes,
      },
    })

    this.uppy.use(this.uppyDashboardModule, {
      inline: true,
      target: this.triggerTarget,
      proudlyDisplayPoweredByUppy: false,
      hideUploadButton: true,
      showProgressDetails: true,
      showRemoveButtonAfterComplete: true,
    })

    this.uppy.use(ActiveStorageUploadPlugin, {
      directUploadUrl: this.directUploadUrlValue,
    })

    this.uppy.on('upload', () => {
      this.disableSubmitButtons(true)
    })

    // `complete` event fires on both successful and failed uploads
    this.uppy.on('complete', () => {
      this.disableSubmitButtons(false)
    })

    this.uppy.on('upload-success', (_file, response) => {
      this.signedId = response?.body?.signed_id || ''
      this.fieldTarget.setAttribute('value', this.signedId)
    })

    this.uppy.on('file-removed', () => {
      this.signedId = ''
      this.fieldTarget.setAttribute('value', '')
    })
  }

  disconnect() {
    this.uppy.destroy()
  }

  fieldTargetConnected(target: Element) {
    this.signedId = target.getAttribute('value') || ''
  }

  clear() {
    this.fieldTarget.setAttribute('value', '')
  }

  undo() {
    this.fieldTarget.setAttribute('value', this.signedId)
  }

  disableSubmitButtons(disable: boolean) {
    const submitButtons = this.element
      .closest('form')
      ?.querySelectorAll<HTMLButtonElement>('button[type="submit"]')

    if (submitButtons) {
      submitButtons.forEach((button) => {
        button.disabled = disable
      })
    }
  }

  get acceptedFileTypes(): string[] | undefined {
    return this.acceptValue.length == 0 ? undefined : this.acceptValue
  }
}
