import Uppy from '@uppy/core'
import BasePlugin from '@uppy/core/lib/BasePlugin'
import type { DefinePluginOpts, PluginOpts } from '@uppy/core/lib/BasePlugin'
import type { UppyFile, Body, Meta } from '@uppy/utils/lib/UppyFile'
import { DirectUpload, DirectUploadDelegate } from '@rails/activestorage'
import type { Blob } from '@rails/activestorage'

interface ActiveStorageOptions extends PluginOpts {
  directUploadUrl: string
}

const defaultOptions = {} satisfies Partial<ActiveStorageOptions>

export class ActiveStorageUploadPlugin<
  M extends Meta,
  B extends Body
> extends BasePlugin<
  DefinePluginOpts<ActiveStorageOptions, keyof typeof defaultOptions>,
  M,
  B
> {
  declare directUploadUrl: string

  constructor(uppy: Uppy<M, B>, opts?: ActiveStorageOptions) {
    super(uppy, opts)

    this.type = 'uploader'
    this.id = 'ActiveStorageUploadPlugin'
    this.directUploadUrl = this.opts.directUploadUrl

    this.handleUpload = this.handleUpload.bind(this)
  }

  install() {
    this.uppy.addUploader(this.handleUpload)
  }

  uninstall() {
    this.uppy.removeUploader(this.handleUpload)
  }

  async handleUpload(fileIDs: string[]) {
    if (fileIDs.length === 0) {
      this.uppy.log('[XHRUpload] No files to upload!')
      return
    }

    const file = this.uppy.getFile(fileIDs[0])
    this.uppy.emit('upload-start', [file])
    await this.uploadFile(file)
  }

  uploadFile(file: UppyFile<M, B>) {
    return new Promise((resolve, reject) => {
      const directUploadDidProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.uppy.emit('upload-progress', file, {
            uploadStarted: file.progress.uploadStarted ?? 0,
            bytesUploaded: event.loaded,
            bytesTotal: event.total,
          })
        }
      }

      const directHandler: DirectUploadDelegate = {
        directUploadWillStoreFileWithXHR: (request: XMLHttpRequest) => {
          request.upload.addEventListener('progress', (event) =>
            directUploadDidProgress(event)
          )
        },
      }

      const upload = new DirectUpload(
        file.data as File,
        this.directUploadUrl,
        directHandler
      )

      upload.create((error: Error, blob: Blob) => {
        if (error) {
          this.uppy.emit('upload-error', file, error)
          return reject(error)
        } else {
          this.uppy.emit('upload-success', file, {
            status: 200,
            body: {
              ...blob,
            } as unknown as B,
          })
          return resolve(file)
        }
      })
    })
  }
}
